@import './third-party/include-media.scss';

$primary-color: #2a7886;
$secondary-color: #512b58;
$dark-color: #333;
$light-color: #fff;

$alert-color: #aa5555;

@mixin shadow ($depth, $opacity: 0.2) {
  box-shadow: #{$depth + 1}px #{$depth + 1}px #{$depth + 2}px rgba(0,0,0, $opacity);
}

@mixin spin-animation ($duration: 1) {
  animation: spin #{$duration}s linear infinite;
  transform-origin: center center;
}