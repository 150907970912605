.input-file {
  margin-bottom: 15px;
}

.checkbox {
  font-size: .8rem;
}

.select {
  position: relative;
  // display: inline-block;
  width: 100%;

  select {
    font-family: 'Arial';
    display: inline-block;
    width: 100%;
    cursor: pointer;
    padding: 10px 30px 10px 15px;
    outline: 0;
    border: 0px solid $dark-color;
    border-radius: 0px;
    background: darken($color: $light-color, $amount: 10);
    color: darken($color: $light-color, $amount: 50);
    white-space: nowrap;
    overflow-x: hidden;
    text-overflow: ellipsis;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none; 
    
    &::-ms-expand {
      display: none;
    }

    &:hover,
    &:focus {
      color: $dark-color;
      background: darken($color: $light-color, $amount: 25)
    }

    &:disabled {
      opacity: 0.5;
      pointer-events: none;
    }
  }

  &__arrow {
    position: absolute;
    top: 16px;
    right: 15px;
    pointer-events: none;
    border-style: solid;
    border-width: 8px 5px 0px 5px;
    border-color: #7b7b7b transparent transparent transparent;
  }
}

.select select:hover ~ .select__arrow,
.select select:focus ~ .select__arrow {
    border-top-color: #000000;
}
.select select:disabled ~ .select__arrow {
    border-top-color: #cccccc;
}

.form-field {
  font-size: .9rem;
  margin-bottom: 15px;
  display: grid;
  height: fit-content;

  &--row {
    grid-auto-flow: column;
    justify-content: start;
  }

  &--inline-btn {
    grid-template-areas:
    "label label"
    "input btn";
    grid-template-columns: 1fr 40px;

    .form-field__label {
      grid-area: label;
    }
    .form-field__select {
      grid-area: input;
    }
    .btn, .btn-icon {
      grid-area: btn;
    }
  }

  &__input,
  &__select {
    height: 38px;
  }

  &__input,
  &__textarea,
  &__select {
    color: $dark-color;
    background-color: darken($color: $light-color, $amount: 10);
    border: 0;
    outline: 0;
    margin: 0;
    box-sizing: border-box;
    text-overflow: ellipsis;
    padding: 8px;
    width: 100%;

    &:disabled {
      color: darken($color: $light-color, $amount: 25);
      cursor: not-allowed;
    }
  }
  
  &__input,
  &__textarea {
    &:read-only {
      color: lighten($color: $dark-color, $amount: 30);
      background-color: darken($color: $light-color, $amount: 5);
    }
  }

  &__checkbox + label {
    margin: auto 0;
    align-self: center;
  }
  &__label {
    color: $dark-color;
    margin-bottom: 5px;
    grid-row: 1;
  } 
}
.form-actions {
  display: inline-flex;
  align-items: center;
  justify-content: flex-end;
}