.editable-note {
  position: relative;
}
.editable-note__textarea {
  margin: 0;
  width: 100%;
  min-height: 0px;
  height: 100%;
  border: 0;
  color: $dark-color;
  overflow-y: hidden;
  resize: vertical;

  &:focus {
    outline: 0;
  }
}

.editable-note__actions {
  display: flex;
  justify-content: space-around;
  position: absolute;
  right: 0;
  bottom: -35px;
  margin-right: 10px;
}

.editable-note__save-btn,
.editable-note__cancel-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0;
  border-radius: 4px;
  font-size: 1rem;
  margin-right: 5px;
  color: $primary-color;
  padding: 8px;
  cursor: pointer;
  background-color: darken($color: $light-color, $amount: 15);
  @include shadow($depth: 3);
  transition: .5s all ease;

  &:hover {
    background-color: $primary-color;
    color: $light-color;
    @include shadow($depth: 5);
  }
}