@import './cards/simple-card.scss';
@import './cards/info-card.scss';

// accounts card begin

.accounts-list-card {
  display: grid;
  grid-template-areas: 
  "head subhead"
  "sum sum"
  "count count";
  grid-template-columns: min-content 1fr;
  grid-template-rows: min-content 1fr min-content;
  
  color: $dark-color;
  text-decoration: none;
  font-size: 0.8rem;
  
  padding: 10px 20px;
  background-color: $light-color;
  min-height: 120px;
  cursor: pointer;
  
  animation: slide-up .6s ease;

  @include shadow($depth: 1)
}

.accounts-list-card:hover {
  @include shadow($depth: 4)
}

.accounts-list-card__head {
  font-size: 1rem;
  margin: auto 0;
  grid-area: head;
}
.accounts-list-card__subhead {
  margin: auto 15px;
  grid-area: subhead;
}
.accounts-list-card__sum {
  font-size: 1.8rem;
  margin: auto;
  grid-area: sum;
}
.accounts-list-card__count {
  text-align: end;
  grid-area: count;
}

// accounts card end


// auxiliary-list-card

.auxiliary-list-card {
  display: grid;
  grid-template-areas: 
  "info-left info-right"
  "info-left info-right"
  "value value"
  "footer footer";
  grid-template-columns: 1fr 1fr;
  
  text-decoration: none;
  color: $dark-color;
  font-size: 0.8rem;
  
  padding: 20px;
  background-color: $light-color;
  min-height: 200px;
  cursor: pointer;

  
  animation: slide-up .6s ease;
  
  @include shadow($depth: 1);
  
  &--secondary {
    background-color: $secondary-color;
    color: $light-color;
  }
}

.auxiliary-list-card:hover {
  @include shadow($depth: 4)
}

.auxiliary-list-card__info-right,
.auxiliary-list-card__info-left {
  margin: auto;
  text-align: center;
  padding: 5px;
  min-height: 60px
}

.auxiliary-list-card__info-right {
  grid-area: info-right;
}

.auxiliary-list-card__info-left {
  grid-area: info-left;
}

.auxiliary-list-card__value {
  grid-area: value;
  text-align: center;
  font-size: 1.5rem;
  padding: 15px;
}

.auxiliary-list-card__footer {
  grid-area: footer; 
  text-align: center; 
}

// auxiliary-list-card end

// default card
.card {
  display: grid;
  grid-template-columns: 1fr minmax(70px, max-content);
  grid-template-areas: 
  "title actions"
  "body body"
  "loading loading"
  "footer footer";
  
  background-color: $light-color;
  padding: 20px 20px;

  animation: slide-up .6s ease;

  @include shadow($depth: 1);
}

.card--primary {
  background-color: $primary-color;

  .card__title {
    color: $light-color;
  }
}

.card__title {
  grid-area: title;
  font-size: 0.9rem;
  padding: 0 0 15px;
  color: $dark-color;
}

.card__body {
  grid-area: body;
}

.card__footer {
  grid-area: footer;
}