
.main-containt-loading {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 4rem;
  margin: 20px;
  height: 100%;
  svg {
    color: rgba($primary-color, 0.4);
    @include spin-animation(1.2);
  }
}

.main-containt-loading--tiny {
  font-size: 1rem;
  margin: 5px;
}