.sidenav {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 240px;
  position: fixed;
  overflow-y: auto;
  z-index: 2;
  transform: translateX(-245px);
  transition: all .6s ease-in-out;
  background-color: $light-color;

  @include shadow($depth: 2);
  @include media(">tablet") {
    position: relative;
    transform: translateX(0);
  }

  &.sidenav.active {
    transform: translateX(0);
  }

  &__close-icon {
    position: absolute;
    visibility: visible;
    top: 8px;
    right: 12px;
    cursor: pointer;
    font-size: 20px;
    color: $primary-color;

    @include media(">tablet") {
      visibility: hidden;
    }
  }

  &__list-item {
    color: #ddd;
  }

  &__list-link {
    display: block;
    padding: 15px 20px;
    font-size: .9rem;
    color: $dark-color;
    text-decoration: none;
    white-space: nowrap;
    overflow-x: hidden;
    text-overflow: ellipsis;
  }

  &__list-link:hover {
    background-color: darken($color: $light-color, $amount: 15);
    cursor: pointer;
  }

  &__list {
    position: relative;
    padding: 15px 0 20px;
    margin: 0 0 0;
    list-style: none;
        
    &::before {
      content: attr(data-title);
      font-size: .8rem;
      position: absolute;
      background-color: darken($color: $light-color, $amount: 15);
      color: $dark-color;
      padding: 4px 0;
      top: -8px;
      text-align: center;
      width: 100%;
    }
  }

  &__profile {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    padding: 15px 5px;
    background-color: darken($color: $light-color, $amount: 8);
    min-height: 120px;

    &-picture {
      border-radius: 50%;
      width: 75px;
      height: 75px;
      background-color: $primary-color;
      border: 0;
    }

    &-name {
      font-size: .8rem;
    }

    &-actions {
      align-self: flex-end;
      > * {
        margin-right: 10px;
      }
    }
  }
}