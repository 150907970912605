.unstyled-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.error {
  :invalid {
    border: 1px solid red;
  }
}

:required ~ label {
  &::after {
    content: '*';
    margin-left: 5px;
    color: red;
  }
}

.hidden {
  padding: 0;
  max-height: 0;
  opacity: 0;
  overflow: hidden;
  transition: all 1s ease;

  &.show {
    position: initial;
    max-height: 1000px;
    padding: initial;
    transition: all 1s ease;
    opacity: 1;
    overflow: unset;
    animation: slide-up .6s ease;
  }
}

.crossed {
  text-decoration: line-through;
}

.text-warn {
  color: darken($color: yellow, $amount: 25);
}

.loading {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  &__spin {
    color: $primary-color;
    font-size: 1rem;
    @include spin-animation(1.2);
  }
}