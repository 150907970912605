.login-page {
  width: 100vw;
  height: 100vh;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  background-color: $primary-color;
}

.forms-wrapper {
  position: relative;
  z-index: 1;
  background: $light-color;
  max-width: 360px;
  margin: 0 10px;
  padding: 45px;
  text-align: center;
  
  @include shadow($depth: 2);
  
  input {
    font-family: "Roboto", sans-serif;
    outline: 0;
    background: darken($color: $light-color, $amount: 15);
    width: 100%;
    border: 0;
    margin: 0 0 15px;
    padding: 15px;
    box-sizing: border-box;
    font-size: 14px;
  }
  
  button {
    font-family: "Roboto", sans-serif;
    text-transform: uppercase;
    outline: 0;
    background: $secondary-color;
    width: 100%;
    border: 0;
    padding: 15px;
    color: $light-color;
    font-size: 14px;
    -webkit-transition: all 0.3 ease;
    transition: all 0.3 ease;
    cursor: pointer;
  }

  button:hover,button:active,button:focus {
    background: lighten($color: $secondary-color, $amount: 15);
  }

  .message {
    margin: 15px 0 0;
    color: $dark-color;
    font-size: 12px;
  }

  .message a {
    color: $secondary-color;
    text-decoration: none;
  }
}