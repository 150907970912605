@import "./components/editable-note.scss";
@import "./components/main-containt-loading.scss";
@import "./components/sidenav.scss";
@import "./components/charts.scss";
@import "./components/snackbar.scss";
@import "./components/tooltip.scss";
@import "./components/cards.scss";
@import "./components/labels.scss";
@import "./components/table.scss";
@import "./components/link.scss";

.nav-control {
  display: inline-flex;
  flex-wrap: wrap;
  background-color: $light-color;
  align-items: center;
  min-height: 46px;
  @include shadow($depth: 1);

  > div {
    margin: 8px;
    max-width: 250px;
  }
}