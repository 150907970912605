.modal-wrapper {
  display: none;
  position: fixed;
  z-index: 999;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba($dark-color, .8);
  opacity: 0;
  transition: all .5s ease;

  &.show {
    display: block;
    opacity: 1;
  }
}

.modal {
  display: flex;
  flex-direction: column;
  background-color: $light-color;
  margin: 10vh auto;
  padding: 15px;
  width: 80vw;
  max-width: 600px;
  animation: slide-up .8s ease;
  @include shadow($depth: 7, $opacity: .6);

  &__header {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.2rem;
    color: $light-color;
    font-weight: bold;
    border-radius: 3px;

    background-color: $primary-color;
    position: relative;
    top: -30px;

    padding: 8px;
    @include shadow($depth: 2, $opacity: .4);
  }
}

.event-description-modal {
  display: none;
  position: fixed;
  z-index: 999;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba($dark-color,0.8);
  opacity: 0;
  transition: all .5s ease;

  &.show {
    opacity: 1;
    display: block;
  }
}

.event-description-modal__body {
  display: flex;
  flex-direction: column;
  background-color: $light-color;
  margin: 15% auto;
  padding: 15px;
  width: 80vw;
  max-width: 600px;
  animation: slide-up .8s ease;
  @include shadow($depth: 7, $opacity: .6);
}

.event-description-modal__content {
  textarea {
    width: 100%;
  }
}

.event-description-modal__header {
  align-items: center;
  
  text-align: center;
  color: $light-color;
  
  border-radius: 3px;
  background-color: $secondary-color;
  position: relative;
  top: -30px;

  font-size: 1.2rem;
  font-weight: bold;
  padding: 8px;
  @include shadow($depth: 2, $opacity: .4);
}

.event-description-modal__footer {
  display: flex;
  justify-content: flex-end;
}