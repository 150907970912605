
.tooltip {
  position: relative;

  &__text {
    visibility: hidden;
    width: 120px;
    background-color: $dark-color;
    color: $light-color;
    text-align: center;
    padding: 8px 5px;
    border-radius: 6px;
  
    position: absolute;
    z-index: 1;
  
    &--top-left {
      width: 120px;
      bottom: calc(100% + 10px);
      left: 60px;
      margin-left: -60px;
    }

    &::after {
      content: " ";
      position: absolute;
      top: 100%;
      left: 50%;
      margin-left: -5px;
      border-width: 5px;
      border-style: solid;
      border-color: $dark-color transparent transparent transparent;
    }
    
    opacity: 0;
    transition: all .8s ease;
  }


  &:hover .tooltip__text {
    visibility: visible;
    opacity: 1;
  }
}

