/* Style buttons */
.btn {
  margin: 4px;
  border: none; 
  color: $primary-color;
  background-color: $light-color;
  padding: 10px 15px;
  outline: 0;
  font-size: 1rem;
  cursor: pointer;
  transition: all .3s ease;

  &:disabled {
    color: darken($color: $light-color, $amount: 25);
    cursor: not-allowed;
  }

  &:hover:not(:disabled) {
    color: darken($color: $primary-color, $amount: 20);
    background-color: darken($color: $light-color, $amount: 5);
    @include shadow($depth: 1);
  }
}

.btn-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;

  padding: 6px 8px;
  border-radius: 2px;
  border: 0;
  font-size: 1rem;
  margin: 0;

  color: $dark-color;
  background-color: unset;
  outline: none;
  text-decoration: none;
  cursor: pointer;
  transition: all .5s ease;

  &:disabled {
    color: darken($color: $light-color, $amount: 25);
    cursor: not-allowed;
  }

  &:hover:not(:disabled) {
    color: $light-color;
    background-color: $primary-color;
    @include shadow($depth: 3);
  }

  &--secondary {
    background-color: $secondary-color;
    color: $light-color;

    &:hover {
      background-color: lighten($color: $secondary-color, $amount: 20);
    }
  }

  &--primary {
    background-color: $primary-color;
    color: $light-color;

    &:hover {
      background-color: lighten($color: $primary-color, $amount: 5);
    }
  }
}