.snackbar-wrapper{
  display: flex;
  z-index: 9999;
  flex-direction: column;
  padding: 0;
  margin: 0;
  position: fixed;
  left: 50%;
  bottom: 30px;
  z-index: 999;
}
.snackbar {
  visibility: hidden; 
  opacity: 0;
  min-width: 250px;
  margin-left: -125px;
  margin-top: 10px;
  background-color: $secondary-color;
  color: $light-color; 
  text-align: center;
  border-radius: 2px;
  padding: 16px;
  transition: all .5s ease;

  &.show {
    visibility: visible;
    opacity: 1;
    -webkit-animation: slide-up 0.5s;
    animation: slide-up 0.5s;
  }
}
