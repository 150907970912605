@import "styles/variables.scss";
@import "styles/animation.scss";
@import "styles/layout.scss";
@import "styles/buttons.scss";
@import "styles/lists.scss";
@import "styles/form.scss";
@import "styles/modal.scss";
@import "styles/components.scss";
@import "styles/login-page.scss";
@import "styles/utilities.scss";

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


html {
  scroll-behavior: smooth;
}

.to-top-button {
  display: none;
  position: fixed;
  bottom: 20px;
  right: 30px;
  
  font-size: 1.5rem;
  padding: 8px 5px 0;
  border: 0;
  border-radius: 2px;
  background-color: $primary-color;  
  color: $light-color;

  z-index: 99;
  cursor: pointer;
  outline: none;

  transition: all .5s ease;
  animation: slide-up .5s ease;
  @include shadow($depth: 2, $opacity: .4);

  &:hover {
    background-color: darken($color: $primary-color, $amount: 20);  
  } 
}


/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: $light-color;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: $primary-color;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: darken($color: $primary-color, $amount: 20);
}