.link {
  background-color: unset;
  outline: none;
  text-decoration: none;
  cursor: pointer;
  color: $primary-color;
  transition: all .3s ease;

  &:hover {
    font-weight: bolder;
  }
}