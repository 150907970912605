.simple-card {
  display: flex;
  flex-direction: column;
  
  text-decoration: none;
  color: $dark-color;
  
  padding: 20px 15px 5px;
  margin-top: 10px;    
  background-color: $light-color;

  animation: slide-up .6s ease;

  @include shadow($depth: 1);

  &--fit-content {
    height: fit-content;
  }

  &__featured {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.2rem;
    color: $light-color;
    font-weight: bold;
    border-radius: 3px;

    background-color: $primary-color;
    position: relative;
    top: -30px;

    padding: 8px;
    @include shadow($depth: 2, $opacity: .4);
  }

  &__description {
    color: lighten($color: $dark-color, $amount: 15);
    font-size: 1rem;
    margin: 5px 0 15px;
    height: 100%;
  }

  &__labels {
    font-size: .8rem;
    padding: 15px 0;

    > :not(:first-child) {
      margin-left: 10px;
    }
  }

  &__actions {
    display: inline-flex;
    justify-content: flex-end;
    align-items: center;
    margin: 0;
    padding: 10px;

    > *:not(:first-child) {
      margin-left: 8px;
    }
  }

  &--secondary {
    .simple-card__featured {
      background-color: $secondary-color;
    }
  }
}