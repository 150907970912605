@import './charts/gantt.scss';

.chart {
  width: 100% !important;
  height: auto !important;
}

.btn--chart-fullsize {
  z-index: 0;
  position: absolute;
  display: flex;
  top: 10px;
  right: 15px;
  z-index: 99;
}

.chart--fullsize {
  .btn--chart-fullsize {
    top: 10px;
    right: 12px;
  }
}

.chart-wrapper {
  position: relative;
  transition: all .5s ease;
  @include shadow($depth: 1);
  
  &.chart--fullsize {
    z-index: 99999;
    top: 0;
    left: 0;
    position: absolute;
    padding: 0;
    margin: 2vh 2vw;
    width: 96vw;
    height: 96vh;
    @include shadow($depth: 10, $opacity: .5);
    
    .chart-container {
      padding: 20px;
      max-height: 100%;
      height: 100%;
    }

    &::before {
      z-index: -99999;
      content: ' ';
      width: 100vw;
      height: 100vh;
      background-color: $dark-color;
      position: fixed;
      top: 0;
      left: 0;
      opacity: .8;
    }
  }
  .chart-container {
    background-color: $light-color;
    height: 100%;
  }
}