.scroll-list-wrapper {  
  position: relative;
  max-height: 340px;
  overflow-y: auto;
  transition: all 1s ease;
}

.scroll-list-wrapper__loading {
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(transparent 10%, $light-color 90%);
  position: sticky;
  height: 60px;
  bottom: 0;
}

.scroll-list-wrapper__loading-spin {
  color: $primary-color;
  font-size: 23px;
  @include spin-animation(1.2);
}

.credit-events-list {
  list-style: none;
  padding: 0;
  margin: 0;
  
  &__item {
    display: grid;
    margin-right: 10px;
    grid-template-columns: 1fr 100px;
    grid-template-rows: repeat(3, 20px);

    animation: slide-up 1s ease;

    &:not(:first-child) {
      margin-top: 10px;
      padding-top: 12px;
      border-top: 1px solid darken($color: $light-color, $amount: 20);
    }
  }

  &__doc {
    color: $dark-color;
    font-size: .9rem;
    white-space: nowrap;
    overflow-x: hidden;
    text-overflow: ellipsis;

    grid-row: 1;
    grid-column: 1;
  }

  &__detailing {
    color: lighten($color: $dark-color, $amount: 20);
    font-weight: normal;
    font-size: .8rem;
    padding-left: 10px;
  }

  &__date {
    color: lighten($color: $dark-color, $amount: 20);
    font-size: .8rem;
    grid-row: 2;
    grid-column: 1;
  }

  &__value {
    color: $primary-color;
    font-size: .8rem;
    font-weight: bold;
    grid-row: 3;
    grid-column: 1;
  }

  &__actions {
    display: flex;
    align-items: center;
    justify-content: space-around;
    grid-row: 1 / 4;
    grid-column: 2; 
  }

}


// accounts events list
.account-events-documents {

  &__item {
    display: flex;
    align-items: center;
    justify-content: center;
  
    flex-wrap: wrap;
    font-size: 0.8rem;
    color: $dark-color;
    background-color: $light-color;
  
    max-height: 0;
    overflow: hidden;
    transition: all .7s cubic-bezier(0.6, -0.28, 0.735, 0.045);
    
    &.show {
      max-height: 200px;
      margin-bottom: 10px;
    }
  }


  &__actions {
    padding: 10px 15px;
  }
  
  &__type {
    white-space: nowrap;
    overflow-x: hidden;
    text-overflow: ellipsis;
    font-weight: bold;
    flex-grow: 1;
    text-align: center;
    padding: 10px 0;
    width: 100%;
    
    @include media(">tablet") {
      padding: 10px 20px;
      width: unset;
    }
  }
  
  &__description {
    max-height: 0;
    overflow: auto;
    background-color: darken($color: $light-color, $amount: 15);
    width: 100%;
    padding: 0 15px;
    transition: all .4s ease;
    
    &.show {
      max-height: 120px;
    }
  }
  
  &__date {
    width: 180px;
    padding: 5px 15px;
  }

  &__id {
    width: 120px;
    padding: 5px 15px;
  }

  &__value {
    padding: 15px 0;
    width: 120px;
    text-align: center;
    font-weight: bold;
    color: $primary-color;
  }

  &__balance {
    padding: 15px 0;
    width: 120px;
    text-align: center;
    color: $dark-color;
  }
}


// accounts events list end

// comparation list begin

.account-comparation__definition {
  font-size: 0.9rem;
  margin: 5px 0;
  color: $dark-color;
}

.comparation-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.comparation-list__item {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin: 15px 0;
  background-color: darken($color: $light-color, $amount: 20);
  animation: slide-up 1s ease;
}

.comparation-list__header {
  font-size: 0.8rem;
  font-weight: bold;
  grid-column: 1 / 3;
  text-align: center;
  padding: 7px 0;
  color: $dark-color;
}

.comparation-list__subheader {
  display: flex;
  justify-content: center;
  font-size: 0.8rem;
  grid-column: 1 / 3;
  padding: 0 15px 20px;
  color: $dark-color;
  > svg {
    padding-right: 10px;
  }
  &:hover {
    color: $secondary-color;
    font-weight: bold;
    cursor: pointer;
  }
}

.comparation-list__first-element,
.comparation-list__second-element {
  font-size: 0.8rem;
  padding: 7px 0 7px 0;
  text-align: center;
  background-color: darken($color: $light-color, $amount: 25);
}
.comparation-list__first-element {
  color: lighten($color: $dark-color, $amount: 20);
}

.comparation-list__second-element {
  color: $primary-color;
}

.comparation-list__difference-element {
  font-size: 0.9rem;
  padding: 10px 0 5px 0;
  grid-column: 1 / 3;
  text-align: center;
  color: $alert-color;
}

// comparation list end

// events list begin

.events__definition {
  font-size: 0.9rem;
  margin: 3px 0;
  color: $dark-color;
}

.events-list {
  list-style: none;
  padding: 10px 0;
  margin: 0;

  &__item {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin: 10px 0;
    background-color: darken($color: $light-color, $amount: 20);
    box-shadow: 1px 1px rgba(30,30,30, 0.2);
    animation: slide-up 1s ease;
  }

  &__date,
  &__doc {
    font-size: 0.9rem;
    padding: 7px 10px;
    color: $dark-color;
  }

  &__doc {
    text-align: end;
  }

  &__type {
    grid-column: 1 / 3;
    font-size: 0.8rem;
    padding: 10px 10px;
    color: $dark-color;
    background-color: darken($color: $light-color, $amount: 25);
  }

  &__actions {
    display: flex;
    justify-content: flex-end;
    margin-right: 5px;
  }

  &__value {
    font-size: 0.9rem;
    align-items: center;
    padding: 7px 10px;
    margin: auto 0;
    color: $primary-color;
  }

  &__check {
    padding: 8px 10px;
    color: $secondary-color;
  }

  &__loading {
    display: inline-flex;
    color: $primary-color;
    padding: 8px 10px;
    animation: spin 1s linear infinite;
    transform-origin: center center;
  }
}





.associated-events-list {
  &__item {
    padding: 0 8px;
    display: flex;
    align-items: center;
    color: $dark-color;

    &:nth-child(odd) {
      background-color: darken($color: $light-color, $amount: 10);
    }
  }
  &__value {
    min-width: 120px;
    font-size: .9rem;
  }
  &__name {
    padding: 5px;
    font-size: .9rem;
  }
  &__actions {
    margin: 5px 0;
    flex-grow: 1;
    text-align: right;
    .btn {
      margin-left: 3px;
    }
  }
  &__empty {
    font-size: .9rem;
    color: $dark-color;
  }
}