.info-card {
  position: relative;
  
  display: flex;
  align-items: center;
  justify-content: center;
  
  padding: 20px;
  background-color: $light-color;
  @include shadow($depth: 1);

  animation: slide-up .6s ease;

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;

    position: absolute;
    top: -15px;
    left: 10px;

    font-size: 1.1rem;
    padding: 8px;
    color: $light-color;
    background-color: lighten($color: $dark-color, $amount: 50);
    @include shadow($depth: 1);
  }

  &__info {
    color: $dark-color;
    font-weight: bold;
    font-size: 1.5rem;
  }
}