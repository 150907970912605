$bar-color: #b8c2cc !default;
$bar-stroke: #8D99A6 !default;
$border-color: #e0e0e0 !default;
$light-bg: #f5f5f5 !default;
$light-border-color: #ebeff2 !default;
$light-yellow: #fcf8e3 !default;
$text-muted: #666 !default;
$text-light: #555 !default;
$text-color: #333 !default;
$blue: #a3a3ff !default;
$handle-color: #ddd !default;

.gantt-chart-wrapper {
  display: none;
  position: fixed;
  z-index: 999;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba($dark-color, .8);
  opacity: 0;
  transition: all .5s ease;
	
	&__close-btn {
		position: absolute;
		top: calc(2vh + 5px);
		right: calc(2vw + 5px);
		margin: 5px;
	}
	
  &.show {
    display: block;
    opacity: 1;
  }
}

.gantt-container {
	position: relative;
	overflow: auto;
  background-color: $light-color;
  margin: 2vh 2vw;
	padding: 0;
	box-sizing: border-box;
	width: 96vw;
	height: 96vh;
	animation: slide-up .8s ease;
	@include shadow($depth: 7, $opacity: .6);
}
.gantt {
	.grid-background {
		fill: none;
	}
	.grid-header {
		fill: #ffffff;
		stroke: $border-color;
		stroke-width: 1.4;
	}
	.grid-row {
		fill: #ffffff;
	}
	.grid-row:nth-child(even) {
		fill: $light-bg;
	}
	.row-line {
		stroke: $light-border-color;
	}
	.tick {
		stroke: $border-color;
		stroke-width: 0.2;
		&.thick {
			stroke-width: 0.4;
		}
	}
	.today-highlight {
		fill: $light-yellow;
		opacity: 0.5;
	}

	.arrow {
		fill: none;
		stroke: $text-muted;
		stroke-width: 1.4;
	}

	.bar {
		fill: $bar-color;
		stroke: $bar-stroke;
		stroke-width: 0;
		transition: stroke-width .3s ease;
		user-select: none;
	}
	.bar-progress {
		fill: $blue;
	}
	.bar-invalid {
		fill: transparent;
		stroke: $bar-stroke;
		stroke-width: 1;
		stroke-dasharray: 5;

		&~.bar-label {
			fill: $text-light;
		}
	}
	.bar-label {
		fill: #fff;
		text-anchor: middle;
		font-size: 12px;
		font-weight: lighter;

		&.big {
			fill: $text-light;
			text-anchor: start;
		}
	}

	.handle {
		fill: $handle-color;
		cursor: ew-resize;
		opacity: 0;
		visibility: hidden;
		transition: opacity .3s ease;
	}

	.bar-wrapper {
		outline: none;

		&:hover {
			.bar {
				fill: darken($bar-color, 5);
			}

			.bar-progress {
				fill: darken($blue, 5);
			}

			.handle {
				visibility: visible;
				opacity: 1;
			}
		}

		&.active {
			.bar {
				fill: darken($bar-color, 5);
			}

			.bar-progress {
				fill: darken($blue, 5);
			}
		}
	}

	.lower-text, .upper-text {
		font-size: 12px;
		text-anchor: middle;
	}
	.upper-text {
		fill: $text-light;
	}
	.lower-text {
		fill: $text-color;
	}

	.hide {
		display: none;
	}
}