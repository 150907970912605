// main grid begin

.container-grid {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 50px 1fr 50px;
  grid-template-areas:
    "header"
    "main"
    "footer";
  height: 100vh;

  @include media(">tablet") {
    grid-template-columns: 240px 1fr;
    grid-template-areas:
    "sidenav header"
    "sidenav main"
    "sidenav footer";
  }
}

.menu-icon {
  position: fixed;
  display: flex;
  top: 5px;
  left: 10px;

  align-items: center;
  justify-content: center;
  
  z-index: 1;
  cursor: pointer;
  outline: none;

  padding: 12px;
  border: 0;
  font-size: 1rem;
  border-radius: 2px;
  background-color: $primary-color;  
  color: $light-color;

  transition: all .5s ease;
  animation: slide-up .5s ease;
  @include shadow($depth: 1, $opacity: .2);

  &:hover {
    background-color: darken($color: $primary-color, $amount: 20);  
  } 
}

.header {
  grid-area: header;
  padding-left: 62px;
  padding-right: 25px;
  font-size: .8rem;
  font-weight: bold;

  @include media (">tablet") {
    padding: 0 20px;
  }
}

.sidenav {
  grid-area: sidenav;
}

.footer {
  grid-area: footer;
  padding: 0 20px;
}

.main {
  grid-area: main;
  background-color: darken($color: $light-color, $amount: 7)
}

.header, .footer {
  z-index: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: $primary-color;
  background-color: darken($color: $light-color, $amount: 4)
}

// main-grid end

.grid-header-divider {
  margin: 35px 20px 15px;
  color: $primary-color;
  border-bottom: 2px solid rgba($primary-color, .6);
  padding: 8px 8px 5px 3px;

  animation: fade-in 1s ease;
}

// auto-fill-grid begin

.auto-fill-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(265px, 1fr));
  gap: 20px;
  margin: 20px;

  &--large {
    grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
  }
}

// auto-fill-grid begin end

.auto-fit-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(265px, 1fr));
  gap: 20px;
  margin: 20px;

  &--large {
    grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
  }
}

// just grid
// main idea from https://dev.to/codyhouse/flash-grid-learn-css-grid-by-building-a-grid-system-2c4f

$grid-columns: 12;

.grid {
  --grid-cols: #{$grid-columns};
  display: grid;
  gap: var(--grid-gap, 0);
  grid-template-columns: repeat(var(--grid-cols), 1fr);
  align-content: flex-start;

  > * {
    grid-column-end: span var(--grid-cols);
  }
}

$gaps-names: xxxxs, xxxs, xxs, xs, sm, md, lg, xl, xxl, xxxl, xxxxl, xxxxxl;

@for $i from 1 through $grid-columns {
  .grid--#{$i} { --grid-cols: #{$i}; }
  
  .grid--gap-#{nth($gaps-names, $i)} { --grid-gap: #{$i * 0.125 + 0.125}rem; }

  .col-#{$i}  { 
    grid-column-end: span #{$i}; 
  }

  .col-#{$i}-phone {
    @include media("<tablet") {
      grid-column-end: span #{$i}; 
    }
  }
 
  .col-#{$i}-tablet {
    @include media(">=tablet") {
      grid-column-end: span #{$i}; 
    }
  }

  .col-#{$i}-desktop {
    @include media(">=desktop") {
      grid-column-end: span #{$i}; 
    }
  }
}