.table {
  width: 100%;
  border-collapse: collapse;
  
  thead {
    background-color: darken($color: $light-color, $amount: 15);
    th {
      border: 1px solid white;
      padding: 10px 15px;

      font-size: 1rem;
      font-weight: normal;
    }
  }

  tbody {
    tr {
      &.odd {
        background-color: darken($color: $light-color, $amount: 10);
      }
      td {
        &.center {
          text-align: center;
        }
        padding: 7px 10px;
        font-size: .8rem;
      }
    }
  }

}
.table-wrapper {  
  max-height: 500px;
  overflow-y: auto;
  transition: all 1s ease;
}